//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

import cm from "./PageCover.module.scss"

interface Props {
  title: string
  image: string
  withCategories?: boolean
}

const PageCover: React.FC<Props> = ({ title, image, withCategories }) => {
  return (
    <div
      className={`${cm.pageCover} ${withCategories ? cm.withCategories : ""}`}
    >
      <img src={image} alt="" />

      <div className={cm.text}>
        <h1>{title}</h1>
      </div>
    </div>
  )
}

export default PageCover
