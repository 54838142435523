//import { Link, useStaticQuery, graphql } from "gatsby";
//import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Link } from "gatsby"
import cm from "./Categoires.module.scss"
import { filterNull } from "../utils/typeguard"
import { AppContext } from "../utils/appContext"
import Gql from "../typings/gql"
import SVGCollection from "./SVGCollection"
import { SVGHcLife } from "./SVGCollection"

interface Props {
  fullWidth?: boolean
  subpage?: boolean
  displayedCategories?: Gql.Maybe<Pick<Gql.Wordpress__Category, "acf">>[]
}

const Categories: React.FC<Props> = ({
  fullWidth,
  subpage,
  displayedCategories,
}) => {
  const { categories } = useContext(AppContext)
  return (
    <div
      className={`${cm.categoires} ${fullWidth ? "" : cm.sidebar} ${
        subpage ? cm.subpage : ""
      }`}
    >
      <div className={`${fullWidth ? "row flex-align-middle" : ""}`}>
        {(displayedCategories || categories)
          .filter(filterNull)
          .map(category => {
            const SVGComponent =
              SVGCollection[category.acf?.category_icon || ""] || SVGHcLife
            return (
              <div
                className={`columns text-center ${fullWidth ? "medium-3" : ""}`}
              >
                <Link
                  to={`/${category.acf?.acf_slug}`}
                  className={`${cm.categoryLink} ${cm[
                    category.acf?.category_icon || ""
                  ] || ""}`}
                >
                  <SVGComponent width={48} height={48} />
                  {category.acf?.display_name}
                </Link>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Categories
