//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import chunk from "lodash/chunk";
import PostListContent from "./PostListContent"

import cm from "./PostList.module.scss"
import Gql from "../typings/gql"

interface Props {
    page: Gql.ListPageQuery["archivePosts"];
}

const PostList: React.FC<Props> = ({ page }) => {
    if (!page) {
        return null;
    }
    return (
        <div className={`${cm.postList}`}>
            {chunk(page.nodes, 2).map(([node1, node2]) =>
                <div className={`${cm.postListContainer}`} key={node1.id}>
                    <div className={`${cm.postListBlock}`}>
                        <PostListContent post={node1} />
                        {node2 && <PostListContent post={node2} />}
                    </div>
                </div>
             )}
        </div>
    )
}

export default PostList
