//import PropTypes from "prop-types"
import React from "react"
import { Link, withPrefix } from "gatsby"
import { parseISO, format } from "date-fns"

import cm from "./PostListContent.module.scss"
import Gql from "../typings/gql"
import { DATE_FORMAT_STRING } from "../constants";
import { getRoute } from "../utils/i18n"

interface Props {
    post: Gql.ListPageQuery["archivePosts"]["nodes"][0];
}

const PostListContent: React.FC<Props> = ({ post }) => {
    const postContent = post.excerpt || (post.content || "").split("<!--more-->")[0].substr(0, 250);
    return (
        <Link to={`/${getRoute("article")}/${post.slug}`} className={cm.postListContent}>
            <div className="row flex-align-middle collapse">
                <div className="columns medium-6">
                    <div className={cm.postListImage}>
                        <img src={post.featured_media?.source_url || withPrefix("/images/cover-2.jpg")} alt="" />
                    </div>
                </div>
                <div className="columns medium-6">
                    <div className={cm.text}>
                        {/* <h3>KIEMELT BEJEGYZÉS CÍME TÖBB SORBA TÖRVE loremipsum</h3> */}
                        <h3>{post.title}</h3>
                        <div className={cm.lead} dangerouslySetInnerHTML={{ __html: postContent }} />
                        <div className={cm.info}>
                            <span className={cm.category}>
                                {post.categories?.[0]?.acf?.display_name || "Nincs kategória"}
                        </span>
                            <span className={cm.date}>
                                {format(parseISO(post.date), DATE_FORMAT_STRING)}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default PostListContent
