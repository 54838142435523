import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PostList from "../components/PostList"
import Categories from "../components/Categories"
import PageCover from "../components/PageCover"
import Pagination from "../components/Pagination"
import Gql from "../typings/gql"
import { POST_PER_PAGE } from "../constants"
import { AppContext } from "../utils/appContext";
import { getRoute } from "../utils/i18n"

interface Props {
    data: Gql.ListPageQuery;
    pageContext: { skip: number; limit: number } & AppContext;
}

const ListPage: React.FC<Props> = ({ data, pageContext: { skip, categories, mainMenu, mainSiteUrl } }) => {
    const currentPage = Math.floor(skip / POST_PER_PAGE) + 1;
    const pageCount = Math.ceil(data.archivePosts.totalCount / POST_PER_PAGE);
    return (
        <Layout categories={categories} mainMenu={mainMenu} mainSiteUrl={mainSiteUrl}>
            <SEO />

            <PageCover title="Hírarchívum" image={withPrefix("/images/cover-1.jpg")} withCategories />

            <div className="main bg-gray">

                <Categories fullWidth subpage />

                <PostList page={data.archivePosts} />

                <Pagination currentPage={currentPage} pageCount={pageCount} url={`/${getRoute("archive")}`} />

            </div>

        </Layout>
    );
}

export default ListPage

export const pageQuery = graphql`
  query ListPage($skip: Int!, $limit: Int!, $lang: String!) {
    archivePosts: allWordpressPost(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }, filter: {categories: {elemMatch: {acf: {language: {eq: $lang}}}}}) {
        totalCount
        nodes {
            id
            title
            content
            slug
            excerpt
            modified
            date
            featured_media {
                source_url
            }
            categories {
                acf {
                    acf_slug
                    category_icon
                    display_name
                }
                id
            }
        }
    }
}`;
